import React, { useEffect, useState } from 'react';
import { CssBaseline, Box, Container } from '@material-ui/core';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { theme } from '../../javascript/config/themeOnaBet';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import OnaBetFooter from '../onabet_components/OnaBetFooter.js'
// import Banners from '../single-page/pages/Banners.js'
import SignIn from './SignIn.js'
import SignUp from './SignUp.js'
// import Termos from '../single-page/pages/Termos.js'
// import Faq from '../single-page/pages/Faq.js'
import ResetPassword from './ResetPassword.js'
import RequestPasswordReset from './RequestPasswordReset.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../onabet-public/styles/main.scss";
import OnaBet from 'images/logo-onabet.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100%',
    '@media(max-width: 768px)': {
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(9),
    }
  },
  marcaImg: {
    alignSelf: 'center',
    width: 'auto',
    height: '60px',
    marginTop: '10px',
    '@media(max-width: 600px)': {
      width: 'auto',
      height: '60px',
    }
  },
  banner: {
    padding: '0px',
    textAlign: 'center'
  },
  '& .MuiAppBar-positionFixed': {
    position: 'relative'
  },
  footer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    backgroundColor: '#FF283C',
    marginTop: '64px',
    '@media(max-width: 1280px)': {
      position: 'relative'
    },
    '@media(max-width: 600px)': {
      marginTop: '0px',
    }
  }
}));

export default function OnaBetPublic({ flash, global_schema: globalSchema = { current_affiliate: {} } }) {
  const classes = useStyles();

  useEffect(() => {
    // Atualiza o titulo do documento usando a API do browser
    if (flash.notice) {
      toast.info(flash.notice);
    }
    if (flash.notice) {
      toast.warning(flash.alert);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ToastContainer autoClose={8000} />
        <CssBaseline />
        <AppBar style={{ position: 'relative' }}>
          <Toolbar style={{ justifyContent: 'center', color: '#fff' }}>
            <Link to="/">
              <img src={OnaBet} className={classes.marcaImg} alt="" title={"icone"} />
            </Link>
          </Toolbar>
        </AppBar>
        <Container className={classes.paper} maxWidth="xs">
          <Route exact path={["/", "/public", "/public/sign_in"]}>
            <SignIn />
          </Route>
          <Route exact path="/public/reset_password">
            <ResetPassword />
          </Route>
          <Route exact path="/public/request_password_reset">
            <RequestPasswordReset />
          </Route>
        </Container>
        <Box className={classes.footer}>
          <OnaBetFooter />
        </Box>
      </Router>
    </ThemeProvider>
  );
}
