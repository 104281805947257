import React, { useState } from 'react';
import { TextField, FormControlLabel, Checkbox, Typography, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import AuthorizationClient from '../single-page/api/AuthorizationClient.js'
import LoadingButton from '../components/LoadingButton.js'
import loginArc from 'images/loginArc.svg'

const useStyles = makeStyles((theme) => ({
  h1: {
    margin: '0px 30px',
    marginRight: 'auto',
    fontFamily: 'BebasNeue-Bold',
    fontSize: '2rem'
  },
  h4: {
    margin: '0px 30px',
    marginRight: 'auto'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    border: '1px solid #FF283C',
    padding: '32px 32px 20px 32px',
    backgroundColor: '#333333',
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
    fontFamily: 'BebasNeue-Bold'
  },
  submitSignUp: {
    margin: theme.spacing(0),
  },
  forgetPassword: { 
    color: '#fff',
    padding: '10px 20px',
    '@media(max-width: 768px)': {
      textAlign: 'center'
    },
    '@media(max-width: 500px)': {
      fontSize: '0.89rem'
    },
    '@media(max-width: 375px)': {
      fontSize: '0.73rem'
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // border: '1px solid #FF283C'
  },
  signInHeader: {
    width: '100%',
    backgroundColor: '#FF283C',
    paddingBottom: theme.spacing(2)
  },
  loginArc: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '10%'
  },
  link: {
    color: '#FF283C',
    textDecoration: 'none',
    fontWeight: 'bold'
  },
  link2: {
    color: '#fff',
    textDecoration: 'none'
  }
}));

export default function SignIn() {
  const classes = useStyles();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  function sign_in(e) {
    e.preventDefault();
    document.activeElement.blur();

    setLoading(true);
    AuthorizationClient.signIn({ login: login, password: password, bet_type: 'onabet' })
      .then(function () {
        window.location.href = '/'
      })
      .catch(function (error) {
        if (error.response?.status == 401) {
          setLoginError(error.response.data?.error);
        } else {
          setLoginError();
        }
      })
      .then(function () {
        setLoading(false);
      });
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={classes.paper}>
      <div className={classes.signInHeader}>
        <div className={classes.loginArc}>
          <img src={loginArc} />
        </div>
        <Typography className={classes.h1} component="h1" variant="h5">
          LOGIN
        </Typography>
        <Typography className={classes.h4} component="h4">
          Entre com o login e senha recebidos
        </Typography>
      </div>
      <form className={classes.form} onSubmit={sign_in} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Email / Username"
          value={login}
          error={loginError}
          helperText={loginError}
          onChange={(e) => setLogin(e.target.value)}
          autoComplete="email"
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={password}
          error={passwordError}
          helperText={passwordError}
          onChange={(e) => setPassword(e.target.value)}
          label="Senha"
          type={showPassword ? "text" : "password"}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          type="submit"
          fullWidth
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          ENTRAR
        </LoadingButton>
        {/* Não será usado */}
        {/* <LoadingButton
          fullWidth
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.submitSignUp}
        >
          <Link className={classes.link2} to="/public/sign_up">
            CADASTRE-SE
          </Link>
        </LoadingButton> */}
      </form>
      <Grid container style={{ display: "flex", flexDirection: "column" }}>
        <Grid className={classes.forgetPassword} item xs>
          {"Esqueceu o usuário ou senha? "}
          <Link
            className={classes.link}
            to="/public/request_password_reset"
            variant="body2"
          >
            {" Clique aqui"}
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
