import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import footerLogo from 'images/footerLogo.svg'
import facebook from 'images/facebook.svg'
import instagram from 'images/instagram.svg'
import twitter from 'images/twitter.svg'

const useStyles = makeStyles((theme) => ({
  footerMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#fff',
    '@media(max-width: 1280px)': {
      flexDirection: 'column-reverse',
    }
  },
  footerLogo: {
    '@media(max-width: 1280px)': {
      display: 'none',
    }
  },
  footerMenuCenter: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 'auto',
    '@media(max-width: 1280px)': {
      margin: '0%'
    },
    '@media(max-width: 600px)': {
      flexDirection: 'column',
      margin: '5% 5%'
    }
  },
  footerMenuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    padding: '0% 5%',
    '@media(max-width: 1280px)': {
      marginBottom: '2%'
    },
    '@media(max-width: 600px)': {
      marginBottom: '5%'
    }
  },
  socialMedia: {
    display: 'flex',
    padding: '0% 15%',
    '@media(max-width: 1280px)': {
      padding: '3%',
      justifyContent: 'center'
    },
    '@media(max-width: 600px)': {
      padding: '5%',
      display: 'flex',
      justifyContent: 'space-evenly'
    }
  },
  titleMenuItem: {
    textTransform: 'uppercase',
    '@media(max-width: 1280px)': {
      fontSize: '1rem'
    },
  },
  titleMenuBody: {
    '@media(max-width: 1280px)': {
      fontSize: '0.8rem'
    },
  },
  socialMediaIcon: {
    margin: '0% 25%',
    '@media(max-width: 1280px)': {
      margin: '0% 5%',
    },
    '@media(max-width: 600px)': {
      margin: '0%',
    }
  }
}));

export default function OnaBetFooter() {
  const classes = useStyles();

  return (
    <div className={classes.footerMenu}>
      <img src={footerLogo} className={classes.footerLogo} />
      <div className={classes.footerMenuCenter}>
        <div className={classes.footerMenuItem}>
          <Typography className={classes.titleMenuItem}>
            Loren Ipsum
          </Typography>
          <Typography className={classes.titleMenuBody}>
            Tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
        </div>
        <div className={classes.footerMenuItem}>
          <Typography className={classes.titleMenuItem}>
            Loren Ipsum
          </Typography>
          <Typography className={classes.titleMenuBody}>
            Tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
        </div>
        <div className={classes.footerMenuItem}>
          <Typography className={classes.titleMenuItem}>
            Loren Ipsum
          </Typography>
          <Typography className={classes.titleMenuBody}>
            Tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
        </div>
      </div>
      <div className={classes.socialMedia}>
        <img className={classes.socialMediaIcon} src={instagram} width="25" />
        <img className={classes.socialMediaIcon} src={twitter} width="25" />
        <img className={classes.socialMediaIcon} src={facebook} width="15" />
      </div>
    </div>
  );
}